import React, { useState, useEffect, createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useHistory for redirection
import API from './api'; // Import your API class

// Create a context to manage user state
// const UserContext = createContext();

// Custom hook to fetch user data
export const useMe = ( source = null, successRedirectUrl = null, failRedirectUrl = '/login' ) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate(); // Get the history object for redirection

  useEffect(() => {
    const fetchUser = async () => {
      const { success, data } = await API.me();
      if (success) {
        setUser(data); // Set the user data if successful
        if (successRedirectUrl !== null) {
            navigate(successRedirectUrl, { state: { source, status: 'success' } }); // Redirect to success page
        }
      } else {
        setUser(null); // Reset user data if request fails
        if (failRedirectUrl !== null) {
            navigate(failRedirectUrl, { state: { source, status: 'failed' } }); // Redirect to login page
        }
      }
    };

    fetchUser(); // Fetch user data when component mounts
  }, [navigate]);

  return user;
};

// Provider component to wrap your app and provide user data to children
// export const UserProvider = ({ children }) => {
//   const user = useMe(); // Fetch user data using the custom hook

//   return (
//     <UserContext.Provider value={user}>
//       {children}
//     </UserContext.Provider>
//   );
// };

// // Custom hook to access user data
// export const useUser = () => {
//   return useContext(UserContext);
// };

// export default useUser;
