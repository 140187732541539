import React, { useState } from "react";
import {
    HomeIcon,
    CogIcon,
    ArrowLeftEndOnRectangleIcon,
    UserPlusIcon,
    ChartPieIcon,
    CurrencyDollarIcon,
    UserGroupIcon,
    ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/outline"; // Importing Hero Icons

const Sidebar = ({ onSidebarItemClick, user }) => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleItemClick = (component) => {
        onSidebarItemClick(component);
        toggleSidebar(); // Close the sidebar after clicking an item
    };

    return (
        <div className="hidden lg:flex">
            {" "}
            {/* Set parent container to full height */}
            <div
                className={`bg-gray-900 text-white w-64 space-y-6 py-7 px-2 inset-y-0 left-0 transform '-translate-x-full'`}
            >
                {/* <button onClick={toggleSidebar} className="text-white flex items-center space-x-2 px-4 focus:outline-none"> */}
                <button className="text-white flex items-center space-x-2 px-4 focus:outline-none">
                    {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg> */}
                    <span className="text-2xl font-bold sm:text-3xl">
                        DataConverse
                    </span>
                </button>
                <nav>
                    <a
                        href="#"
                        className="py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 flex items-center"
                        onClick={() => handleItemClick("overview")}
                    >
                        <ChartPieIcon className="h-5 w-5 mr-2" />{" "}
                        {/* Hero Icon for home */}
                        Overview
                    </a>
                    {user && user["role"] === "Admin" && (
                        <a
                            href="#"
                            className="py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 flex items-center"
                            onClick={() => handleItemClick("billing")}
                        >
                            <CurrencyDollarIcon className="h-5 w-5 mr-2" />{" "}
                            {/* Hero Icon for settings */}
                            Billing
                        </a>
                    )}
                    {/* <a href="#" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 flex items-center" onClick={() => handleItemClick('billing')}>
            <BanknotesIcon className="h-5 w-5 mr-2" />
            Billing
          </a> */}{" "}
                    {/* Hero Icon for logout */}
                    {user && user["role"] === "Admin" && (
                        <a
                            href="#"
                            className="py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 flex items-center"
                            onClick={() => handleItemClick("invite")}
                        >
                            <UserPlusIcon className="h-5 w-5 mr-2" />{" "}
                            {/* Hero Icon for logout */}
                            Invite
                        </a>
                    )}
                    {user && user["role"] === "Admin" && (
                        <a
                            href="#"
                            className="py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 flex items-center"
                            onClick={() => handleItemClick("users")}
                        >
                            <UserGroupIcon className="h-5 w-5 mr-2" />{" "}
                            {/* Hero Icon for settings */}
                            Users
                        </a>
                    )}
                    {user && user["role"] === "Admin" && (
                        <a
                            href="#"
                            className="py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 flex items-center"
                            onClick={() => handleItemClick("settings")}
                        >
                            <CogIcon className="h-5 w-5 mr-2" />{" "}
                            {/* Hero Icon for settings */}
                            Settings
                        </a>
                    )}
                    {user && user["org"] && user["org"]["is_paid"] && (<a
                        href="#"
                        className="py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 flex items-center"
                        onClick={() => handleItemClick("chat")}
                    >
                        <ChatBubbleLeftRightIcon className="h-5 w-5 mr-2" />{" "}
                        {/* Hero Icon for logout */}
                        Chat
                    </a>)}
                </nav>
            </div>
            {/* <div className="flex-1 p-10 text-2xl font-bold">
        {!isOpen && (
          <button onClick={toggleSidebar} className="text-gray-500 focus:outline-none">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        )}
      </div> */}
        </div>
    );
};

export default Sidebar;
