class API {
    static ROOT_DOMAIN_DEV = "http://localhost:8000";
    static ROOT_DOMAIN_DEV_DOCKER = "http://localhost";
    static STRIPE_URL_PAYMENT_LINK =
        "https://buy.stripe.com/test_9AQ8zaav6e5K6HKcMP";
    static ROOT_DOMAIN_PROD = "https://api.mydatamazing.com"; // this is prod api change grader to api
    static ROOT_DOMAIN = API.ROOT_DOMAIN_PROD;
    static BASE_URL = "/v1";
    static BASE_URL_API = "/api/v1";

    static async process_get_request(url, method = "GET", body = null) {
        try {
            let request_dict = {
                method: method,
                credentials: "include",
            }
            if (method === "POST" || method === "PUT") {
                request_dict["body"] = JSON.stringify(body);
            }
            const response = await fetch(url, request_dict);

            if (response.ok) {
                return { success: true, data: await response.json() };
            } else {
                return { success: false, data: await response.json() };
            }
        } catch (error) {
            console.error("Error:", error);
            return { success: false, error };
        }
    }

    static async register(formValues) {
        try {
            const response = await fetch(`${API.ROOT_DOMAIN}${API.BASE_URL}/user/register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formValues),
            });

            if (response.ok) {
                // Registration successful, handle accordingly
                return { success: true, data: await response.json() };
            } else {
                // Registration failed, handle accordingly
                return { success: false, error: await response.json() };
            }
        } catch (error) {
            console.error("Error:", error);
            return { success: false, error };
        }
    }

    static async registerUserToOrg(formValues) {
        try {
            const response = await fetch(`${API.ROOT_DOMAIN}${API.BASE_URL}/user`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formValues),
            });

            if (response.ok) {
                // Registration successful, handle accordingly
                return { success: true, data: await response.json() };
            } else {
                // Registration failed, handle accordingly
                return { success: false, error: await response.json() };
            }
        } catch (error) {
            console.error("Error:", error);
            return { success: false, error };
        }
    }

    static async login(formValues) {
        try {
            const response = await fetch(`${API.ROOT_DOMAIN}${API.BASE_URL}/user/login`, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(formValues),
            });

            if (response.ok) {
                // Login successful, handle accordingly
                return { success: true };
            } else {
                // Login failed, handle accordingly
                return { success: false };
            }
        } catch (error) {
            console.error("Error:", error);
            return { success: false, error };
        }
    }

    static async forgotPassword(formValues) {
        try {
            const response = await fetch(`${API.ROOT_DOMAIN}${API.BASE_URL}/user/forgot-password`, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(formValues),
            });

            if (response.ok) {
                return { success: true, data: await response.json() };
            } else {
                return { success: false, data: await response.json() };
            }
        } catch (error) {
            console.error("Error:", error);
            return { success: false, error };
        }
    }

    static async resetPassword(formValues) {
        try {
            const response = await fetch(`${API.ROOT_DOMAIN}${API.BASE_URL}/user/reset-password`, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(formValues),
            });

            if (response.ok) {
                return { success: true };
            } else {
                return { success: false, data: "Reset password token has expired. Please generate new link using forgot password." };
            }
        } catch (error) {
            console.error("Error:", error);
            return { success: false, error };
        }
    }

    static async logout() {
        try {
            const response = await fetch(`${API.ROOT_DOMAIN}${API.BASE_URL}/user/logout`, {
                method: "GET",
                credentials: "include",
                // body: JSON.stringify(formValues)
            });

            if (response.ok) {
                // Login successful, handle accordingly
                return { success: true };
            } else {
                // Login failed, handle accordingly
                return { success: false };
            }
        } catch (error) {
            console.error("Error:", error);
            return { success: false, error };
        }
    }

    static async me() {
        try {
            const response = await fetch(
                `${API.ROOT_DOMAIN}${API.BASE_URL_API}/user/me`,
                {
                    method: "GET",
                    credentials: "include",
                }
            );

            if (response.ok) {
                // Login successful, handle accordingly
                return { success: true, data: await response.json() };
            } else {
                // Login failed, handle accordingly
                return { success: false, error: await response.json() };
            }
        } catch (error) {
            console.error("Error:", error);
            return { success: false, error };
        }
    }

    static async inviteUser(formValues) {
        try {
            const response = await fetch(`${API.ROOT_DOMAIN}${API.BASE_URL_API}/user/invite`, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(formValues),
            });

            if (response.ok) {
                // Login successful, handle accordingly
                return { success: true, data: await response.json() };
            } else {
                // Login failed, handle accordingly
                return { success: false, error: await response.json() };
            }
        } catch (error) {
            console.error("Error:", error);
            return { success: false, error };
        }
    }

    static async getOrgUsers() {
        try {
            const response = await fetch(
                `${API.ROOT_DOMAIN}${API.BASE_URL_API}/user`,
                {
                    method: "GET",
                    credentials: "include",
                }
            );

            if (response.ok) {
                // Login successful, handle accordingly
                return { success: true, data: await response.json() };
            } else {
                // Login failed, handle accordingly
                return { success: false, error: await response.json() };
            }
        } catch (error) {
            console.error("Error:", error);
            return { success: false, error };
        }
    }

    static getInviteUrl(token) {
        return `${API.ROOT_DOMAIN}/invite-validate?code=${token}`;
    }

    static getStripeUrl(orgId) {
        return `${API.STRIPE_URL_PAYMENT_LINK}?client_reference_id=${orgId}`;
    }

    static async saveSalesforceSettings(formValues) {
        try {
            const response = await fetch(
                `${API.ROOT_DOMAIN}${API.BASE_URL_API}/org/salesforce`,
                {
                    method: "POST",
                    credentials: "include",
                    body: JSON.stringify(formValues),
                }
            );

            if (response.ok) {
                // Login successful, handle accordingly
                return { success: true, data: await response.json() };
            } else {
                // Login failed, handle accordingly
                return { success: false, error: await response.json() };
            }
        } catch (error) {
            console.error("Error:", error);
            return { success: false, error };
        }
    }

    static async getSalesforceSettings() {
        try {
            const response = await fetch(
                `${API.ROOT_DOMAIN}${API.BASE_URL_API}/org/salesforce`,
                {
                    method: "GET",
                    credentials: "include"
                }
            );

            if (response.ok) {
                // Login successful, handle accordingly
                return { success: true, data: await response.json() };
            } else {
                // Login failed, handle accordingly
                return { success: false, error: await response.json(), status: response.status };
            }
        } catch (error) {
            console.error("Error:", error);
            return { success: false, error };
        }
    }

    static async updateUser(userID, formValues) {
        try {
            const response = await fetch(
                `${API.ROOT_DOMAIN}${API.BASE_URL_API}/${userID}`,
                {
                    method: "PUT",
                    credentials: "include",
                    body: JSON.stringify(formValues),
                }
            );

            if (response.ok) {
                // Login successful, handle accordingly
                return { success: true, data: await response.json() };
            } else {
                // Login failed, handle accordingly
                return { success: false, error: await response.json() };
            }
        } catch (error) {
            console.error("Error:", error);
            return { success: false, error };
        }
    }
}

export default API;
