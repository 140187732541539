import { useState, useEffect } from "react";
import { EyeIcon, EyeSlashIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { useNavigate, useLocation } from "react-router-dom";
import Header from "./Header";
import API from "./core/api";
import { useMe } from "./core/user";

export default function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [registerSuccess, setRegisterSuccess] = useState("");
    
    const [emailError, setEmailError] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [password, setPassword] = useState(false);

    // If already logged in redirect to dashboard
    const user = useMe('login', '/dashboard', null);

    const location = useLocation();
    const { state } = location;
    const { source, status } = state || {};

    const navigate = useNavigate();

    const authLinks = [
        // { name: 'Log in', href: '/login' },
        { name: "Register", href: "/register" },
    ];

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError("Please enter a valid email address.");
            setIsEmailValid(false);
            return false;
        }
        setEmailError("");
        setIsEmailValid(true);
        return true;
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
    };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        if (!validateEmail(newEmail)) {
            // Set error if email is invalid
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError("");
        }
    };

    const handleClose = (e) => {
        setRegisterSuccess("");
    };

    const handleLoginFailedClose = (e) => {
        setLoginError("");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Get form data
        const formData = new FormData(e.target);
        const formValues = {};
        formData.forEach((value, key) => {
            formValues[key] = value;
        });

        // Call API.register
        const result = await API.login(formValues);
        if (result.success) {

            const { success, data } = await API.me();
            if(success && data && data['role'] === 'User') {
                window.location.assign("https://app.mydatamazing.com"); // Redirect to chat page
            } else if (success && data && data['role'] === 'Admin') {
                navigate("/dashboard", { state: { source: 'login', status: 'success', user } }); // Redirect to dashboard page
            }

            // Handle successful login
        } else {
            setLoginError("Login failed");
        }
    };


    useEffect(() => {
        if (source === "registration" && status === "success") {
            setRegisterSuccess("Registration successful. Please login!");
        }
    }, [source, status]);

    return (
        <>
            <Header authLinks={authLinks} />

            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    {/* <img
                        className="mx-auto h-16 md:h-32 lg:h-48 w-auto"
                        src={logoColor}
                        alt="MyDatamazing"
                    /> */}
                    <h2 className="mt-10 text-left text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Sign in to your account
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">

                {registerSuccess && (
                    <div
                        id="registration-success"
                        className="flex items-center w-full p-2 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                        role="alert"
                    >
                        <div className="text-indigo-500 text-center rounded-md">
                            <p className="m-1 text-sm ">{registerSuccess}</p>
                        </div>
                        <button
                            type="button"
                            className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                            data-dismiss-target="#registration-success"
                            aria-label="Close"
                            onClick={handleClose}
                        >
                            <XMarkIcon className="h-5 w-5 text-gray-500" />
                        </button>
                    </div>
                )}

                    <form className="space-y-6" onSubmit={handleSubmit} >
                        <div>
                            <label
                                htmlFor="email"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    onBlur={handleEmailChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label
                                    htmlFor="password"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Password
                                </label>
                                <div className="text-sm">
                                    <a
                                        href="/forgot-password"
                                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                                    >
                                        Forgot password?
                                    </a>
                                </div>
                            </div>
                            <div className="mt-2 relative">
                                <input
                                    id="password"
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    autoComplete="current-password"
                                    required
                                    onChange={handlePasswordChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                <button
                                    type="button"
                                    onClick={toggleShowPassword}
                                    className="absolute inset-y-0 right-0 flex items-center px-2"
                                >
                                    {showPassword ? (
                                        <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                                    ) : (
                                        <EyeIcon className="h-5 w-5 text-gray-500" />
                                    )}
                                </button>
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                disabled={password === "" || !isEmailValid}
                                className={
                                    password !== "" && isEmailValid
                                        ? "flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        : "flex w-full justify-center rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm"
                                }
                            >
                                Sign in
                            </button>
                        </div>


                        {loginError && (
                            <div
                                id="login-failed"
                                className="flex items-center w-full p-2 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                                role="alert"
                            >
                                <div className= "text-red-500 text-center rounded-md">
                                    <p className="m-1 text-sm ">
                                        {loginError}
                                    </p>
                                </div>
                                <button
                                    type="button"
                                    className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                                    data-dismiss-target="#login-failed"
                                    aria-label="Close"
                                    onClick={handleLoginFailedClose}
                                >
                                    <XMarkIcon  className="h-5 w-5 text-gray-500" />
                                </button>
                            </div>
                        )}

                    </form>
                </div>
            </div>
        </>
    );
}
