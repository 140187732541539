import logoColor from "./logo_dark.svg";
import { useState, useContext } from "react";
import { XMarkIcon, ClipboardIcon } from "@heroicons/react/24/outline";
import API from "./core/api";
import UserContext from './Dashboard'; // Import the context


const InviteSuccess = ({ inviteLink }) => {
    const [copySuccess, setCopySuccess] = useState("");

    const copyToClipboard = () => {
        navigator.clipboard.writeText(inviteLink).then(
            () => {
                setCopySuccess("Link copied!");
                setTimeout(() => setCopySuccess(""), 2000); // Clear message after 2 seconds
            },
            (err) => {
                console.error("Could not copy text: ", err);
            }
        );
    };

    return (
        <div className="flex flex-col mx-auto bg-white p-6 rounded-lg shadow-md w-full max-w-md">
            <h3 className="text-xl font-semibold mb-4">
                Create Invite Success
            </h3>
            <div className="flex justify-between items-center mb-4">
                <div>
                    <p className="text-gray-700 text-sm">
                        Click the button to copy invitation link
                    </p>
                </div>
                <button
                    onClick={copyToClipboard}
                    className="flex items-center p-2 rounded bg-indigo-600 text-white hover:bg-indigo-500 focus:outline-none"
                >
                    <ClipboardIcon className="h-5 w-5" />
                </button>
            </div>
            {copySuccess && (
                <p className="text-green-500 mt-2">{copySuccess}</p>
            )}
        </div>
    );
};

export default function InviteUser( { user } ) {

    const [isEmailValid, setIsEmailValid] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [isInviteSucces, setIsInviteSucces] = useState(false);
    const [inviteError, setInviteError] = useState(false);
    const [inviteLink, setInviteLink] = useState("");

    const [roleId, setRoleId] = useState(1); // Default value as an integer

    // Handle select change and convert value to integer
    const handleRoleChange = (event) => {
        const intValue = parseInt(event.target.value, 10);
        setRoleId(intValue);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError("Please enter a valid email address.");
            setIsEmailValid(false);
            return false;
        }
        setEmailError("");
        setIsEmailValid(true);
        return true;
    };

    const handleInviteFailedClose = (e) => {
        setInviteError("");
    };

    const handleEmailChange = (e) => {
        setIsInviteSucces(false);
        setInviteError("");
        const newEmail = e.target.value;
        if (!validateEmail(newEmail)) {
            // Set error if email is invalid
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError("");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Get form data
        const formData = new FormData(e.target);
        const formValues = {};
        formData.forEach((value, key) => {
            formValues[key] = value;
        });

        // formValues['org_id'] = user.org.org_id;
        formValues['role_id'] = roleId;

        // Call API.register
        const result = await API.inviteUser(formValues);
        if (result.success) {
            setIsInviteSucces(true);
            // Handle successful registration
            const url = API.getInviteUrl(result['data']['token']);
            setInviteLink(url);
        } else {
            const errMsg = result && result['error'] && result['error']['detail'] ? result['error']['detail'] : "Invitation failed"
            setInviteError(errMsg);
        }
    };

    return (
        <div className="py-6 sm:py-6 lg:py-6 bg-white">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="text-left">
                    <p className="mt-2 text-3xl font-bold text-gray-900 sm:text-4xl">
                        Invite User
                    </p>
                </div>

                <div className="sm:mx-auto sm:w-full sm:max-w-lg px-3 shrink-0 md:flex-0">
                    <div className="relative flex flex-col min-w-0 break-words bg-white">
                        <form className="flex-auto p-6" onSubmit={handleSubmit}>
                            <div className="flex flex-wrap -mx-3">
                                <div className="w-full max-w-full px-3 shrink-0 md:w-12/12 md:flex-0">
                                    <div className="mb-4">
                                        <label
                                            htmlFor="email"
                                            className="inline-block mb-2 ml-1 font-medium text-xm text-slate-700 dark:text-white/80"
                                        >
                                            Email address
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            onBlur={handleEmailChange}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div className="w-full max-w-full px-3 shrink-0 md:w-12/12 md:flex-0">
                                    <div className="mb-4">
                                        <label
                                            htmlFor="role_id"
                                            className="inline-block mb-2 ml-1 font-medium text-xm text-slate-700 dark:text-white/80"
                                        >
                                            Role
                                        </label>
                                        <select
                                            value={roleId}
                                            name="role_id"
                                            onChange={handleRoleChange}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        >
                                            <option value={1}>Admin</option>
                                            <option value={2}>User</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    disabled={!isEmailValid}
                                    className={
                                        isEmailValid
                                            ? "flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            : "flex w-full justify-center rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm"
                                    }
                                >
                                    Invite User
                                </button>
                            </div>

                            {inviteError && (
                            <div
                                id="invite-failed"
                                className="flex items-center w-full mt-2 p-2 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                                role="alert"
                            >
                                <div className="text-red-500 text-center rounded-md">
                                    <p className="m-1 text-sm ">
                                        {inviteError}
                                    </p>
                                </div>
                                <button
                                    type="button"
                                    className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                                    data-dismiss-target="#login-failed"
                                    aria-label="Close"
                                    onClick={handleInviteFailedClose}
                                >
                                    <XMarkIcon className="h-5 w-5 text-gray-500" />
                                </button>
                            </div>
                        )}

                        </form>

                        {isInviteSucces && (
                            <InviteSuccess inviteLink={inviteLink} />
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
}
