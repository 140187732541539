import React, { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import {
    Bars3Icon,
    XMarkIcon,
    UserCircleIcon,
} from "@heroicons/react/24/outline";
import logo from "./logo.svg";

const Header = ({ authLinks, isAuthItemsVisibleInNav=true, accountItems = [], accountCallback = null }) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [accountMenuOpen, setAccountMenuOpen] = useState(false);

    const navigation = [
        // { name: 'Home', href: '/' },
        // { name: 'About', href: '/about' },
        // { name: 'Services', href: '/services' },
        // { name: 'Contact', href: '/contact' },
    ];

    return (
        <header className="bg-gray-900 inset-x-0 top-0 z-50 text-white">
            <nav
                className="flex items-center justify-between p-6 lg:px-8"
                aria-label="Global"
            >
                <div className="flex lg:flex-1">
                    <a href="/" className="-m-1.5 p-1.5">
                        <span className="sr-only">MDA</span>
                        <img className="h-8 w-auto" src={logo} alt="" />
                    </a>
                    <div className="flex px-2 items-center">
                        <h1>MyDatamazing</h1>
                    </div>
                </div>

                <div className="flex">
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigation.length > 0 && navigation.map((item) => (
                            <a
                                key={item.name}
                                href={item.href}
                                className="text-sm font-semibold leading-6"
                            >
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end lg:gap-x-6">
                        {isAuthItemsVisibleInNav && authLinks.map((link) =>
                            
                            link.handleAuthCallback === undefined ? (
                                <a
                                    key={link.name}
                                    href={link.href}
                                    className="text-sm font-semibold leading-6"
                                >
                                    {link.name}
                                </a>
                            ) : (
                                <button
                                    key={link.name}
                                    onClick={link.handleAuthCallback}
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    <div className="flex justify-center items-center gap-1 text-center">
                                        {link.icon}
                                        {link.name}
                                    </div>
                                </button>
                            )
                        )}
                    </div>

                    {accountItems && accountItems.length > 0 && (
                        <div className="flex">
                            <button
                                type="button"
                                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
                                onClick={() => setAccountMenuOpen(true)}
                            >
                                <span className="sr-only">Open main menu</span>
                                <UserCircleIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                    )}
                </div>
            </nav>

            <Dialog
                as="div"
                className="lg:hidden"
                open={mobileMenuOpen}
                onClose={() => setMobileMenuOpen(false)}
            >
                <div className="fixed inset-0 z-50" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <img className="h-8 w-auto" src={logo} alt="" />
                        </a>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                {(navigation.length > 0) && navigation.map((item, index) => (
                                    <a
                                        key={`${item.name}-${index}`}
                                        href={item.href}
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                            <div className="py-6">
                            {authLinks.map((link) =>
                            link && (link.handleAuthCallback === undefined) ? (
                                <div className="mx-3 px-3 py-2.5">
                                <a
                                    key={link.name}
                                    href={link.href}
                                    className="text-sm font-semibold leading-6"
                                >
                                    {link.name}
                                </a>
                                </div>
                            ) : (
                                <button
                                    key={link.name}
                                    onClick={link.handleAuthCallback}
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    <div className="flex justify-center items-center gap-1 text-center">
                                        {link.icon}
                                        {link.name}
                                    </div>
                                </button>
                            )
                        )}
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>

            <Dialog
                as="div"
                open={accountMenuOpen}
                onClose={() => setAccountMenuOpen(false)}
            >
                <div className="fixed inset-0 z-50" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <img className="h-8 w-auto" src={logo} alt="" />
                        </a>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setAccountMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="py-6">
                                {accountItems.map((link) => (
                                    link &&
                                    <button
                                        key={link.name}
                                        onClick={link.handleAccountItemClick}
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        <div className="flex justify-center items-center gap-1 text-center">
                                            {link.icon}
                                            {link.name}
                                        </div>
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    );
};

export default Header;
