import logoColor from "./logo_dark.svg";
import API from "./core/api";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {useState} from "react";
import Header from "./Header";

export default function ForgotPassword() {

    const [registerSuccess, setRegisterSuccess] = useState("");
    const authLinks = [
        { name: 'Log in', href: '/login' },
        { name: "Register", href: "/register" },
    ];

    const handleClose = (e) => {
        setRegisterSuccess("");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Get form data
        const formData = new FormData(e.target);
        const formValues = {};
        formData.forEach((value, key) => {
            formValues[key] = value;
        });

        // Call API.register
        const result = await API.forgotPassword(formValues);
        console.log(result);
        if (result.success) {
            setRegisterSuccess(result.data.message);
        } else {
            console.error(result.data);
        }
    };

    return (
        <>
            <Header authLinks={authLinks} />
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Forgot Password
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Send Reset Link
                            </button>
                        </div>
                        {registerSuccess && (
                            <div
                                id="registration-success"
                                className="flex items-center w-full p-2 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                                role="alert"
                            >
                                <div className="text-indigo-500 text-center rounded-md">
                                    <p className="m-1 text-sm ">{registerSuccess}</p>
                                </div>
                                <button
                                    type="button"
                                    className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                                    data-dismiss-target="#registration-success"
                                    aria-label="Close"
                                    onClick={handleClose}
                                >
                                    <XMarkIcon className="h-5 w-5 text-gray-500" />
                                </button>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </>
    )
}
