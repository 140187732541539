import React, { useEffect, useState } from "react";
import { TrashIcon } from "@heroicons/react/24/outline"; // Importing Hero Icons
import API from "./core/api";

export default function Users() {
    const initialPeople = [];

    const [people, setPeople] = useState(initialPeople);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const usersPerPage = 5;

    useEffect(() => {
        const fetchOrgUsers = async () => {
            const result = await API.getOrgUsers();
            if (result.success) {
                setPeople(result.data.map((user) => {
                    return { 
                        name: `${user.first_name} ${user.last_name}`,
                        email: user.email,
                        role: user.role
                    };
                }));
            } else {
                setPeople([]);
            }
            setLoading(false); // Set loading to false once data is fetched
        };

        fetchOrgUsers();
    }, []);

    const handleDelete = (email) => {
        setPeople(people.filter((person) => person.email !== email));
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset to the first page on new search
    };

    const filteredPeople = people.filter((person) =>
        person.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        person.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredPeople.slice(indexOfFirstUser, indexOfLastUser);

    const totalPages = Math.ceil(filteredPeople.length / usersPerPage);

    return (
        <div className="py-6 sm:py-6 lg:py-6 bg-white">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="text-left">
                    <p className="mt-2 text-2xl font-bold text-gray-900 sm:text-3xl">Users</p>
                </div>
                <div className="mt-6 max-w-lg sm:mt-6 lg:mt-6 lg:max-w-none mx-auto">
                    <div className="mt-7">
                        <input
                            type="text"
                            placeholder="Search by name or email"
                            value={searchTerm}
                            onChange={handleSearch}
                            className="p-2 border border-gray-300 rounded w-full"
                        />
                    </div>
                    {loading ? (
                        <div className="flex justify-center items-center mt-6">
                            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
                        </div>
                    ) : (
                        <ul role="list" className="divide-y divide-gray-100">
                            {currentUsers.map((user) => (
                                <li key={user.email} className="flex justify-between gap-x-6 p-5">
                                    <div className="flex min-w-0 gap-x-4">
                                        <div className="min-w-0 flex-auto">
                                            <p className="text-sm font-semibold leading-6 text-gray-900">{user.name}</p>
                                            <p className="mt-1 font-semibold truncate text-xs leading-5 text-gray-500">{user.email}</p>
                                            <p className="mt-1 truncate text-xs leading-3 text-gray-500">{user.role}</p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-x-4">
                                        <button
                                            disabled
                                            onClick={() => handleDelete(user.email)}
                                            className="text-red-600 hover:text-red-800"
                                        >
                                            <TrashIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className="flex justify-center mt-6">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => setCurrentPage(index + 1)}
                            className={`px-3 py-1 mx-1 rounded ${currentPage === index + 1 ? "bg-blue-500 text-white" : "bg-gray-200"}`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}
