import React from "react";
import API from "./core/api";
import { LinkIcon } from "@heroicons/react/24/outline";

const PaidUser = ({ user }) => {
    // const user = {
    //     name: "John Doe",
    //     email: "john.doe@example.com",
    //     role: "Administrator",
    //     organization: "Example Corp",
    // };

    const cards = [
        {
            title: "Links",
            description: (
                <ul role="list" className="divide-y divide-gray-200">
                    <li className="py-4">
                        <div className="flex space-x-3">
                            <div className="flex-1 space-y-1">
                                <dl className="flex justify-left mt-1 text-sm text-gray-600">
                                    <div className="py-2">
                                        <dt className="font-normal text-gray-900">
                                            Status
                                        </dt>
                                        <dd className="text-lg font-semibold text-gray-700 mt-1">
                                            {`Active`}
                                        </dd>
                                    </div>

                                    <div className="flex items-center justify-center pl-10">
                                        <a
                                            href="https://app.mydatamazing.com"
                                            target="_blank"
                                            aria-describedby="tier-basic"
                                            className="block w-[10rem] rounded-lg bg-indigo-600 py-2 text-center text-sm font-semibold text-white hover:bg-indigo-700"
                                        >
                                            Chat Access
                                        </a>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                </ul>
            ),
            icon: <LinkIcon className="h-5 w-5" />,
        },
    ];

    return (
        <div className="py-6 sm:py-6 lg:py-6 bg-white">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="text-left">
                    <p className="mt-2 text-3xl font-bold text-gray-900 sm:text-4xl">
                        Chat
                    </p>
                </div>
                <div className="mt-20 max-w-lg sm:mt-20 lg:mt-20 lg:max-w-none mx-auto grid gap-x-8 gap-y-10 lg:grid-cols-2 lg:gap-y-10">
                    {cards.map((card, index) => (
                        <div
                            key={index}
                            className="flex flex-col justify-between rounded-2xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10"
                        >
                            <div>
                                <div className="flex items-center">
                                    <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                        <div className="text-white">
                                            {card.icon}
                                        </div>
                                    </div>
                                    <h3 className="ml-4 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                                        {card.title}
                                    </h3>
                                </div>
                                <div className="mt-4 text-base leading-7 text-gray-600">
                                    {card.description}
                                </div>
                            </div>

                            {/* <div className="mt-8">
                                <a
                                    href="#"
                                    className="text-sm font-semibold leading-6 text-indigo-600"
                                >
                                    Learn more <span aria-hidden="true">→</span>
                                </a>
                            </div> */}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const UnpaidUser = ({ user }) => {
    const url = API.getStripeUrl(user["org"]["org_uuid"]);

    return (
        <div className="bg-white py-6 sm:py-6 lg:py-6">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="text-left">
                    <p className="mt-2 text-3xl font-bold text-gray-900 sm:text-4xl">
                        Choose the right plan for you
                    </p>
                </div>

                <div className="mt-20 grid gap-8 lg:grid-cols-3">
                    {/* Basic Plan */}
                    <div className="lg:col-start-2 lg:col-span-1 rounded-2xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10">
                        <h3
                            id="tier-basic"
                            className="text-lg font-semibold leading-8 tracking-tight text-gray-900"
                        >
                            Basic
                        </h3>
                        <p className="mt-4 text-3xl font-bold tracking-tight text-gray-900">
                            <span>$50</span>
                            <span className="text-base font-medium text-gray-500">
                                /month
                            </span>
                        </p>
                        <p className="mt-4 text-base font-medium text-gray-500">
                            $450 per month if paid annually
                        </p>
                        <a
                            href={url}
                            aria-describedby="tier-basic"
                            className="mt-8 block w-full rounded-lg bg-indigo-600 py-2 text-center text-sm font-semibold text-white hover:bg-indigo-700"
                        >
                            Buy plan
                        </a>
                        <p className="mt-4 text-base font-medium text-gray-500">
                            Everything necessary to get started.
                        </p>
                        <ul role="list" className="mt-6 space-y-4">
                            <li className="flex items-center text-base font-medium text-gray-500">
                                <svg
                                    className="h-6 w-6 text-green-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                                <span className="ml-3">
                                    Unlimited Dataconverse access
                                </span>
                            </li>
                            <li className="flex items-center text-base font-medium text-gray-500">
                                <svg
                                    className="h-6 w-6 text-green-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                                <span className="ml-3">
                                    Single salesforce instance
                                </span>
                            </li>
                            <li className="flex items-center text-base font-medium text-gray-500">
                                <svg
                                    className="h-6 w-6 text-green-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                                <span className="ml-3">Web chat interface</span>
                            </li>
                            <li className="flex items-center text-base font-medium text-gray-500">
                                <svg
                                    className="h-6 w-6 text-green-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                                <span className="ml-3">Basic support</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Chat = ({ user }) => {
    return user["org"] && user["org"]["is_paid"] ? (
        <PaidUser user={user} />
    ) : (
        <UnpaidUser user={user} />
    );
};

export default Chat;
