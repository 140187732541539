import logo from "./logo.svg";
import "./App.css";

import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import {
    ChartBarIcon,
    MagnifyingGlassIcon,
    Cog8ToothIcon,
    BoltIcon,
    Bars3Icon,
    XMarkIcon,
    CheckCircleIcon,
} from "@heroicons/react/24/outline";

import Header from "./Header";

const navigation = [
    // { name: "DataConverse", href: "/dataconverse" },
    //  { name: 'FlowForge', href: '#' },
    //   { name: 'Marketplace', href: '#' }
];

const HeroSection = () => {
    return (
        <div className="container mx-auto flex flex-col lg:flex-row justify-between bg-white overflow-hidden">
            <video
                className="object-cover sm:h-72 md:h-96 w-50 h-full"
                src="dataconverse.mp4"
                autoPlay
                loop
                muted
            />
            <div className="z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
                    <div className="text-center lg:text-left">
                        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                            <span className="block xl:inline">
                                Augment your
                            </span>{" "}
                            <span className="block text-indigo-600 xl:inline">
                                sales teams
                            </span>
                        </h1>
                        <ul className="mt-4 text-base text-gray-700 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0 list-disc list-inside">
                            <li className="flex items-center">
                                <CheckCircleIcon className="h-6 w-6 text-indigo-600 mr-2" />
                                Quickly identifies and qualifies high-value
                                leads.
                            </li>
                            <li className="flex items-center">
                                <CheckCircleIcon className="h-6 w-6 text-indigo-600 mr-2" />
                                Uncovers hidden sales opportunities.
                            </li>
                            <li className="flex items-center">
                                <CheckCircleIcon className="h-6 w-6 text-indigo-600 mr-2" />
                                Automates routine sales tasks to boost
                                productivity.
                            </li>
                            <li className="flex items-center">
                                <CheckCircleIcon className="h-6 w-6 text-indigo-600 mr-2" />
                                Provides data-driven insights for better
                                decision-making.
                            </li>
                        </ul>
                        <div className="mt-10 mb-2 sm:max-w-xl sm:mx-auto md:mt-12 lg:mx-0">
                            <div className="sm:flex sm:justify-center lg:justify-start">
                                <div className="rounded-md shadow">
                                    <a
                                        href="/register"
                                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                                    >
                                        Get started
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const authLinks = [
    { name: "Log in", href: "/login" },
    { name: "Register", href: "/register" },
];

function Landing() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <div className="bg-white">
            <Header authLinks={authLinks} />

            <div className="mx-0 max-w-100 py-24 sm:px-0 sm:py-0 lg:px-0">
                <div className="relative isolate overflow-hidden bg-gray-900 pt-16 shadow-2xl sm:px-16 md:px-4 md:pt-16 md:pb-16 md:flex md:justify-evenly md:items-center lg:px-24 lg:pt-0" >
                    <svg
                        viewBox="0 0 1024 1024"
                        className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                        aria-hidden="true"
                    >
                        <circle
                            cx={512}
                            cy={512}
                            r={512}
                            fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
                            fillOpacity="0.7"
                        />
                        <defs>
                            <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                                <stop stopColor="#7775D6" />
                                <stop offset={1} stopColor="#E935C1" />
                            </radialGradient>
                        </defs>
                    </svg>

                    {/* <div className="relative mt-16 h-80 lg:mt-8 hidden lg:block">
                        <img
                            className="absolute right-[3rem] top-[1rem] w-[28rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
                            src="chat_1.png"
                            alt="App screenshot"
                            width={1824}
                            height={1080}
                        />
                    </div> */}

                    <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
                        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                            DataConverse
                        </h2>
                        <p className="mt-6 text-lg leading-8 text-gray-300">
                            Introducing AI-Powered business assistant platform
                            for your Teams
                        </p>
                        <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                            <a
                                href="/register"
                                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                            >
                                Get started
                            </a>
                            {/* <a
                                href="#"
                                className="text-sm font-semibold leading-6 text-white"
                            >
                                Learn more <span aria-hidden="true">→</span>
                            </a> */}
                        </div>
                    </div>

                    <div className="flex justify-center items-center mt-20 mb-20">
                        <img
                            className="max-w-[40rem] rounded-md bg-white/5 ring-1 ring-white/10"
                            src="sf_chat.png"
                            alt="App screenshot"
                            width={1824}
                            height={1080}
                        />
                    </div>
                </div>
            </div>

            <div className="isolate bg-white py-16 sm:py-16">
                <HeroSection />
            </div>

            <div className="isolate bg-white py-16 sm:py-16">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:text-center">
                        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            Revolutionize your sales strategy!
                        </p>
                    </div>
                    <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                            <div className="relative pl-16">
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                        <ChartBarIcon className="h-6 w-6 text-white" />
                                    </div>
                                    Gain a Competitive Edge with DataConverse:
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600">
                                    DataConverse leverages intelligent sales
                                    analytics to swiftly identify and qualify
                                    potential leads, empowering your sales team
                                    to prioritize high-value prospects
                                    effectively.
                                </dd>
                            </div>
                            <div className="relative pl-16">
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                        <MagnifyingGlassIcon className="h-6 w-6 text-white" />
                                    </div>
                                    Identifies Sales Opportunities:
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600">
                                    By analyzing data, DataConverse can uncover
                                    hidden sales opportunities within existing
                                    customer bases or in new markets, often
                                    before the competition.
                                </dd>
                            </div>
                            <div className="relative pl-16">
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                        <Cog8ToothIcon className="h-6 w-6 text-white" />
                                    </div>
                                    Streamlines Sales Processes:
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600">
                                    DataConverse can automate routine sales
                                    tasks, freeing up sales professionals to
                                    focus on relationship-building and closing
                                    deals, thus increasing overall productivity.
                                </dd>
                            </div>
                            <div className="relative pl-16">
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                        <BoltIcon className="h-6 w-6 text-white" />
                                    </div>
                                    Experience Real-time Insights:
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600">
                                    Leverage AI to gain immediate data-driven
                                    insights, enhancing decision-making in
                                    sales. Our AI assistant empowers teams to
                                    surpass competitors, seize opportunities,
                                    and maintain a strategic edge in the market.
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>

            <footer className="isolate bg-gray-900 text-white py-4">
                <div className="container mx-auto ">
                    <p>&copy; 2024 MyDatamazing. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
}

function App() {
    return <Landing />;
}

export default App;
