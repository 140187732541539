import { useState } from "react";
import { EyeIcon, EyeSlashIcon, XMarkIcon  } from "@heroicons/react/24/outline";
import logoColor from "./logo_dark.svg";
import API from "./core/api";
import { useNavigate } from "react-router-dom";
import Header from "./Header";

export default function Register() {
    const [showPassword, setShowPassword] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [organisationName, setOrganisationName] = useState("");

    const [password, setPassword] = useState("");
    const [passwordErrors, setPasswordErrors] = useState([]);
    const [emailError, setEmailError] = useState("");

    const [registerError, setRegisterError] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const navigate = useNavigate();

    const authLinks = [
        { name: 'Log in', href: '/login' },
        // { name: 'Register', href: '/register' },
    ];

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const validatePassword = (password) => {
        const errors = [];
        if (password.length < 8) {
            errors.push("Password must be at least 8 characters long.");
        }
        if (!/[a-z]/.test(password)) {
            errors.push("Password must contain at least one lowercase letter.");
        }
        if (!/[A-Z]/.test(password)) {
            errors.push("Password must contain at least one uppercase letter.");
        }
        if (!/\d/.test(password)) {
            errors.push("Password must contain at least one digit.");
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            errors.push(
                "Password must contain at least one special character."
            );
        }
        setIsPasswordValid(errors.length == 0);
        return errors;
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError("Please enter a valid email address.");
            setIsEmailValid(false);
            return false;
        }
        setEmailError("");
        setIsEmailValid(true);
        return true;
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setPasswordErrors(validatePassword(newPassword));
    };

    const handleFirstNameChange = (e) => {
        const firstName = e.target.value;
        setFirstName(firstName);
    };

    const handleLastNameChange = (e) => {
        const lastName = e.target.value;
        setLastName(lastName);
    };

    const handleOrganisationNameChange = (e) => {
        const organisationName = e.target.value;
        setOrganisationName(organisationName);
    };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        if (!validateEmail(newEmail)) {
            // Set error if email is invalid
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError("");
        }
    };

    const handleClose = () => {
        setRegisterError("");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Get form data
        const formData = new FormData(e.target);
        const formValues = {};
        formData.forEach((value, key) => {
            formValues[key] = value;
        });

        // Call API.register
        const result = await API.register(formValues);
        if (result.success) {
            navigate("/login", { state: { source: 'registration', status: 'success' } }); // Redirect to login page
            // Handle successful registration
        } else {
            if(result.error.detail.includes("User already exists with following email")) {
                // Handle registration failure
                setRegisterError("User already exists. Please login.");
            } else {
                setRegisterError("Registration failed");
            }
        }
    };

    return (
        <>
            <Header authLinks={authLinks} />
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    {/* <img
                        className="mx-auto h-8 md:h-16 lg:h-32 w-auto"
                        src={logoColor}
                        alt="MyDatamazing"
                    /> */}
                    <h2 className="mt-10 text-left text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Register your account
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form
                        className="space-y-6"
                        onSubmit={handleSubmit}
                    >
                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                            <div>
                                <label
                                    htmlFor="first_name"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    First Name
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="first_name"
                                        name="first_name"
                                        type="text"
                                        autoComplete="given-name"
                                        onChange={handleFirstNameChange}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div>
                                <label
                                    htmlFor="last_name"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Last Name
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="last_name"
                                        name="last_name"
                                        type="text"
                                        autoComplete="family-name"
                                        required
                                        onChange={handleLastNameChange}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </div>

                        <div>
                            <label
                                htmlFor="org_name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Organisation Name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="org_name"
                                    name="org_name"
                                    type="text"
                                    autoComplete="org_name"
                                    onChange={handleOrganisationNameChange}
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <label
                                htmlFor="email"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    onChange={handleEmailChange}
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            {emailError && (
                                <p className="mt-2 text-sm text-red-600">
                                    {emailError}
                                </p>
                            )}
                        </div>

                        <div>
                            <label
                                htmlFor="password"
                                className={`block text-sm font-medium leading-6 ${
                                    passwordErrors.length
                                        ? "text-red-700"
                                        : "text-gray-900"
                                }`}
                            >
                                Password
                            </label>
                            <div className="mt-2 relative">
                                <input
                                    id="password"
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    autoComplete="new-password"
                                    required
                                    value={password}
                                    onChange={handlePasswordChange}
                                    className={`block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${
                                        passwordErrors.length
                                            ? "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500"
                                            : "text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600"
                                    }`}
                                />
                                <button
                                    type="button"
                                    onClick={toggleShowPassword}
                                    className="absolute inset-y-0 right-0 flex items-center px-2"
                                >
                                    {showPassword ? (
                                        <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                                    ) : (
                                        <EyeIcon className="h-5 w-5 text-gray-500" />
                                    )}
                                </button>
                            </div>
                            {passwordErrors.length > 0 && (
                                <ul className="mt-2 text-sm text-red-600">
                                    {passwordErrors.map((error, index) => (
                                        <li key={index}>{error}</li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        <div>
                            <button
                                type="submit"
                                disabled={
                                    firstName === "" ||
                                    lastName === "" ||
                                    organisationName === "" ||
                                    !isEmailValid ||
                                    !isPasswordValid
                                }
                                className={
                                    firstName !== "" &&
                                    lastName !== "" &&
                                    organisationName !== "" &&
                                    isEmailValid &&
                                    isPasswordValid
                                        ? "flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        : "flex w-full justify-center rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm"
                                }
                            >
                                Register
                            </button>
                        </div>

                        {registerError && (
                            <div
                                id="registration-failed"
                                className="flex items-center w-full p-2 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                                role="alert"
                            >
                                <div className= "text-red-500 text-center rounded-md">
                                    <p className="m-1 text-sm ">
                                        {registerError}
                                    </p>
                                </div>
                                <button
                                    type="button"
                                    className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                                    data-dismiss-target="#registration-failed"
                                    aria-label="Close"
                                    onClick={handleClose}
                                >
                                    <XMarkIcon  className="h-5 w-5 text-gray-500" />
                                </button>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </>
    );
}
