import React from "react";

const Overview = ({ user }) => {
    // const user = {
    //     name: "John Doe",
    //     email: "john.doe@example.com",
    //     role: "Administrator",
    //     organization: "Example Corp",
    // };

    const cards = [
        {
            title: "Account",
            description: (
                <ul role="list" className="divide-y divide-gray-200">
                    <li className="py-4">
                        <div className="flex space-x-3">
                            <div className="flex-1 space-y-1">
                                <dl className="mt-1 text-sm text-gray-600">
                                    <div className="py-2">
                                        <dt className="font-normal text-gray-900">
                                            Name
                                        </dt>
                                        <dd className="text-lg font-semibold text-gray-700 mt-1">
                                         {`${user['user']['first_name']} ${user['user']['last_name']}`}
                                        </dd>
                                    </div>
                                    <div className="py-2">
                                        <dt className="font-normal text-gray-900">
                                            Email
                                        </dt>
                                        <dd className="text-lg font-semibold text-gray-700 mt-1">
                                            {user['user']['email']}
                                        </dd>
                                    </div>
                                    <div className="py-2">
                                        <dt className="font-normal text-gray-900">
                                            Role
                                        </dt>
                                        <dd className="text-lg font-semibold text-gray-700 mt-1">
                                            {user.role}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                </ul>
            ),
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="w-6 h-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 12c2.485 0 4.5-2.015 4.5-4.5S14.485 3 12 3 7.5 5.015 7.5 7.5 9.515 12 12 12zm0 1.5c-3.79 0-7.5 1.917-7.5 4.5v.75h15v-.75c0-2.583-3.71-4.5-7.5-4.5z"
                    ></path>
                </svg>
            ),
        },
        {
            title: "Organization",
            description: (
                <ul role="list" className="divide-y divide-gray-200">
                    <li className="py-4">
                        <div className="flex space-x-3">
                            <div className="flex-1 space-y-1">
                                <dl className="mt-1 text-sm text-gray-600">
                                    <div>
                                        <dt className="font-normal text-gray-900">
                                            Organization Name
                                        </dt>
                                        <dd className="text-lg font-semibold text-gray-700 mt-1">
                                            {user['org']['name']}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </li>
                </ul>
            ),
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="w-6 h-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 3h16.5M3.75 12h16.5M3.75 21h16.5"
                    ></path>
                </svg>
            ),
        },
    ];

    return (
        <div className="py-6 sm:py-6 lg:py-6 bg-white">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="text-left">
                    <p className="mt-2 text-3xl font-bold text-gray-900 sm:text-4xl">
                        Overview
                    </p>
                </div>
                <div className="mt-20 max-w-lg sm:mt-20 lg:mt-20 lg:max-w-none mx-auto grid gap-x-8 gap-y-10 lg:grid-cols-2 lg:gap-y-10">
                    {cards.map((card, index) => (
                        <div
                            key={index}
                            className="flex flex-col justify-between rounded-2xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10"
                        >
                            <div>
                                <div className="flex items-center">
                                    <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                        <div className="text-white">
                                            {card.icon}
                                        </div>
                                    </div>
                                    <h3 className="ml-4 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                                        {card.title}
                                    </h3>
                                </div>
                                <div className="mt-4 text-base leading-7 text-gray-600">
                                    {card.description}
                                </div>
                            </div>

                            {/* <div className="mt-8">
                                <a
                                    href="#"
                                    className="text-sm font-semibold leading-6 text-indigo-600"
                                >
                                    Learn more <span aria-hidden="true">→</span>
                                </a>
                            </div> */}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Overview;
