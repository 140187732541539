import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Account from "./Account";
import Billing from "./Billing";
import AppSettings from "./AppSettings";
import Header from "./Header";
import {
    HomeIcon,
    CogIcon,
    ArrowLeftEndOnRectangleIcon,
    UserPlusIcon,
    ChartPieIcon,
    CurrencyDollarIcon,
    UserGroupIcon,
    ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/outline"; // Importing Hero Icons
import Overview from "./Overview";
import Users from "./Users";
import InviteUser from "./InviteUser";
import Chat from "./Chat";
import { useNavigate } from "react-router-dom";
import API from "./core/api";

import { useMe } from "./core/user";

function Dashboard() {
    const [displayComponent, setDisplayComponent] = useState("overview");
    const [loggedOutStatus, setLoggedOutStatus] = useState("init");

    const user = useMe('dashboard');
    const navigate = useNavigate();

    useEffect(() => {
        if(loggedOutStatus === 'loggedOut') {
            navigate("/login", { state: { source: 'logout', status: 'success' } }); // Redirect to dashboard page
        }
    }, [loggedOutStatus]);

    useEffect(() => {
        const handleLogoutEvent = (e) => {
          if (e.key === 'logout') {
            navigate("/login", { state: { source: 'logout', status: 'success' } }); // Redirect to dashboard page
          }
        };
    
        // Add event listener for storage changes
        window.addEventListener('storage', handleLogoutEvent);
    
        return () => {
          // Clean up the event listener
          window.removeEventListener('storage', handleLogoutEvent);
        };
      }, []);

    const handleLogout = async () => {

        // Call API.logout
        const result = await API.logout();
        if (result.success) {
            // Handle successful logout
            setLoggedOutStatus('loggedOut');
            localStorage.setItem('logout', Date.now());
        } else {
            // TODO: Improve here
        }

    };

    const authLinks = [
        // { name: 'Log in', href: '/login' },
        // { name: 'Register', href: '/register' },
        {
            name: "Overview",
            handleAuthCallback: () => {
                setDisplayComponent("overview");
            },
            icon: <ChartPieIcon className="h-5 w-5 mr-2" />,
        },
        user && user['role'] === 'Admin' && {
            name: "Billing",
            handleAuthCallback: () => {
                setDisplayComponent("billing");
            },
            icon: <CurrencyDollarIcon className="h-5 w-5 mr-2" />,
        },
        user && user['role'] === 'Admin' && {
            name: "Users",
            handleAuthCallback: () => {
                setDisplayComponent("users");
            },
            icon: <UserGroupIcon className="h-5 w-5 mr-2" />,
        },
        user && user['role'] === 'Admin' && {
            name: "Invite",
            handleAuthCallback: () => {
                setDisplayComponent("invite");
            },
            icon: <UserPlusIcon className="h-5 w-5 mr-2" />,
        },
        user && user['role'] === 'Admin' && ({
            name: "Settings",
            handleAuthCallback: () => {
                setDisplayComponent("settings");
            },
            icon: <CogIcon className="h-5 w-5 mr-2" />,
        }),
        user && user["org"] && user["org"]["is_paid"] && {
            name: "Chat",
            handleAuthCallback: () => {
                setDisplayComponent("chat");
            },
            icon: <ChatBubbleLeftRightIcon className="h-5 w-5 mr-2" />,
        },
    ].filter(element => (element !== null) && (element !== false));

    const accountItems = [
        {
            name: "Account",
            handleAccountItemClick: () => {
                setDisplayComponent("account");
            },
            icon: <HomeIcon className="h-5 w-5 mr-2" />,
        },
        {
            name: "Logout",
            handleAccountItemClick: async () => {
                await handleLogout();
            },
            icon: <ArrowLeftEndOnRectangleIcon className="h-5 w-5 mr-2" />,
        },
    ].filter(element => element !== null);

    // Function to handle the callback from Sidebar
    const handleSidebarCallback = (component) => {
        setDisplayComponent(component);
    };

    const userRequired = ( user, Compnent ) => {
        return user !== null && user !== undefined ? <Compnent user={user} /> : (
            <div className="flex justify-center items-center mt-6">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
        </div>
        )
    };

    // Function to render the component based on displayComponent state
    const renderComponent = () => {

        switch (displayComponent) {
            case "overview":
                return userRequired(user, Overview);
            case "billing":
                return userRequired(user, Billing);
            case "users":
                return <Users />;
            case "account":
                return userRequired(user, Account);
            case "settings":
                return <AppSettings />;
            case "invite":
                return userRequired(user, InviteUser);
            case "chat":
                    return userRequired(user, Chat);
            default:
                return null;
        }
    };

    return (
        <>
            <Header authLinks={authLinks} accountItems={accountItems} isAuthItemsVisibleInNav={false} />
            <div className="flex flex-col md:flex-row h-screen">
                <Sidebar onSidebarItemClick={handleSidebarCallback} user={user} />
                <div className="main-content w-full">{renderComponent()}</div>
            </div>
        </>
    );
}

export default Dashboard;
