import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Chat from './Chat';
import Login from './Login';
import Register from './Register';
import ForgotPassword from './ForgotPassword';
import InviteUser from './InviteUser';

import reportWebVitals from './reportWebVitals';

import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Link,
  } from "react-router-dom";
import Dashboard from './Dashboard';
import InviteValidation from './InviteValidation';
import ResetPassword from "./ResetPassword";


// Or use plain objects
const router = createBrowserRouter([
    {
      path: "/",
      element: <App />
    },
    // {
    //     path: "/dataconverse",
    //     element: <Chat />
    // },
    {
        path: "/register",
        element: <Register />
    },
    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/forgot-password",
        element: <ForgotPassword />
    },
    {
        path: "/reset-password",
        element: <ResetPassword />
    },
    {
        path: "/invite-user",
        element: <InviteUser />
    },
    {
        path: "/invite-validate",
        element: <InviteValidation />
    },
    {
        path: "/dashboard",
        element: <Dashboard />
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
