import React, { useEffect, useState } from "react";
import { EyeIcon, EyeSlashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import API from "./core/api";

const AppSettings = () => {
    const [showSecret, setShowSecret] = useState(false);
    const [isSettingsSaveSuccess, setIsSettingsSaveSuccess] = useState(false);
    const [settingsSaveMsg, setSettingsSaveMsg] = useState(null);

    const [salesforceURL, setSalesforceURL] = useState(null);
    const [clientID, setClientID] = useState(null);

    const [isClientSecretValid, setIsClientSecretValid] = useState(false);
    const [isClientIdValid, setIsClientIdValid] = useState(false);
    const [isURLValid, setIsURLValid] = useState(false);


    useEffect(() => {
        const loadCreds = async () => {
            // Call API.register
            const result = await API.getSalesforceSettings();
            if (result.success) {
                setIsSettingsSaveSuccess(true);
                // Handle successful registration
                const salesforceURL = result['data']['data']['salesforceURL'];
                const clientID = result['data']['data']['clientId'];
                setSalesforceURL(salesforceURL);
                setClientID(clientID);
                setIsURLValid(true);
                setIsClientIdValid(true);
            } else {

                let errMsg = null;
                if (result.status == 404) {
                    errMsg = "Please configure your Salesforce.";
                } else {
                    errMsg =
                        result && result["error"] && result["error"]["detail"]
                            ? result["error"]["detail"]
                            : "Load settings failed";
                }
                setIsSettingsSaveSuccess(false);
                setSettingsSaveMsg(errMsg);
            }
        }
        loadCreds();
    }, []);

    const handleURLChange = (e) => {
        const url = e.target.value;
        setSalesforceURL(url);
        if (!validateUrl(url)) {
            // Set error if URL is invalid
            setIsURLValid(false);
        } else {
            setIsURLValid(true);
        }
    };

    const handleClientIDChange = (e) => {
        const clientID = e.target.value;
        setClientID(clientID);
        if (!validateCreds(clientID)) {
            // Set error if email is invalid
            setIsClientIdValid(false);
        } else {
            setIsClientIdValid(true);
        }
    };

    const handleClientSecretChange = (e) => {
        const clientSecret = e.target.value;
        if (!validateCreds(clientSecret)) {
            // Set error if email is invalid
            setIsClientSecretValid(false);
        } else {
            setIsClientSecretValid(true);
        }
    };

    const validateCreds = (cred) => {
        return cred.length > 32
    }

    const validateUrl = (value) => {
        const urlPattern = new RegExp(
          '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$', 'i' // fragment locator
        );
        return !!urlPattern.test(value);
      };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Get form data
        const formData = new FormData(e.target);
        const formValues = {};
        formData.forEach((value, key) => {
            formValues[key] = value;
        });

        // formValues['org_id'] = user.org.org_id;
        // formValues['role_id'] = roleId;

        // Call API.register
        const result = await API.saveSalesforceSettings(formValues);
        if (result.success) {
            setIsSettingsSaveSuccess(true);
            // Handle successful registration
            // const url = API.getInviteUrl(result['data']['token']);
            // setInviteLink(url);
            setSettingsSaveMsg("Settings saved successfully");
        } else {
            const errMsg =
                result && result["error"] && result["error"]["detail"]
                    ? result["error"]["detail"]
                    : "Settings save failed";
            setIsSettingsSaveSuccess(false);
            setSettingsSaveMsg(errMsg);
        }
    };

    const toggleShowSecret = () => {
        setShowSecret(!showSecret);
    };

    const handleSettingsSaveMessage = () => {
        setSettingsSaveMsg("");
    };

    return (
        <div className="py-6 sm:py-6 lg:py-6 bg-white">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="text-left">
                    <p className="m-2 text-3xl font-bold text-gray-900 sm:text-4xl">
                        Salesforce Settings
                    </p>
                </div>

                <div className="sm:mx-auto sm:w-full sm:max-w-3xl px-3 shrink-0 md:flex-0">
                    <div className="relative flex flex-col min-w-0 break-words bg-white">
                        <form className="flex-auto p-6" onSubmit={handleSubmit}>
                            <div className="flex flex-wrap -mx-3">
                                <div className="w-full max-w-full px-3 shrink-0 md:w-12/12 md:flex-0">
                                    <div className="mb-4">
                                        <label
                                            htmlFor="salesforceURL"
                                            className="inline-block mb-2 ml-1 font-medium text-xm text-slate-700 dark:text-white/80"
                                        >
                                            Salesforce URL
                                        </label>
                                        <input
                                            type="salesforceURL"
                                            name="salesforceURL"
                                            defaultValue=""
                                            placeholder="https://org.stage.my.salesforce.com"
                                            value={salesforceURL}
                                            onChange={handleURLChange}
                                            className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div className="w-full max-w-full px-3 shrink-0 md:flex-0">
                                    <label
                                        htmlFor="salesforce-app-id"
                                        className="inline-block mb-2 ml-1 font-medium text-xm text-slate-700 dark:text-white/80"
                                    >
                                        Salesforce App Id
                                    </label>
                                    <div className="mb-4">
                                        <input
                                            id="salesforce-app-id"
                                            type="text"
                                            required
                                            name="clientId"
                                            defaultValue=""
                                            value={clientID}
                                            onChange={handleClientIDChange}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div className="w-full max-w-full px-3 shrink-0 md:flex-0">
                                    <label
                                        htmlFor="salesforce-app-secret"
                                        className="inline-block mb-2 ml-1 font-medium text-xm text-slate-700 dark:text-white/80"
                                    >
                                        Salesforce App Secret
                                    </label>
                                    <div className="mb-4 relative">
                                        <input
                                            id="salesforce-app-secret"
                                            type={
                                                showSecret ? "text" : "password"
                                            }
                                            required
                                            name="clientSecret"
                                            defaultValue=""
                                            onChange={handleClientSecretChange}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        <button
                                            type="button"
                                            onClick={toggleShowSecret}
                                            className="absolute inset-y-0 right-0 flex items-center px-2"
                                        >
                                            {showSecret ? (
                                                <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                                            ) : (
                                                <EyeIcon className="h-5 w-5 text-gray-500" />
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    disabled={
                                        !isURLValid ||
                                        !isClientSecretValid ||
                                        !isClientIdValid
                                    }
                                    className={
                                        isURLValid &&
                                        isClientSecretValid &&
                                        isClientIdValid
                                            ? "flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            : "flex w-full justify-center rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm"
                                    }
                                >
                                    Save Settings
                                </button>
                            </div>

                            {settingsSaveMsg && (
                        <div
                            id="invite-failed"
                            className="flex items-center w-full mt-2 p-2 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                            role="alert"
                        >
                            <div
                                className={`${
                                    isSettingsSaveSuccess
                                        ? "text-green-800"
                                        : "text-red-500"
                                } text-center rounded-md`}
                            >
                                <p className="m-1 text-sm ">
                                    {settingsSaveMsg}
                                </p>
                            </div>
                            <button
                                type="button"
                                className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                                data-dismiss-target="#login-failed"
                                aria-label="Close"
                                onClick={handleSettingsSaveMessage}
                            >
                                <XMarkIcon className="h-5 w-5 text-gray-500" />
                            </button>
                        </div>
                    )}

                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppSettings;
