import logoColor from "./logo_dark.svg";
import {useEffect, useState} from "react";
import API from "./core/api";
import {EyeIcon, EyeSlashIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useMe} from "./core/user";
import Header from "./Header";

export default function ResetPassword() {
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);

    const [apiError, setApiError] = useState("");

    const [password, setPassword] = useState("");
    const [passwordErrors, setPasswordErrors] = useState([]);

    const [repeatPassword, setRepeatPassword] = useState("");
    const [repeatPasswordErrors, setRepeatPasswordErrors] = useState([]);

    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isRepeatPasswordValid, setIsRepeatPasswordValid] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();
    const user = useMe('login', '/dashboard', null);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowRepeatPassword = () => {
        setShowRepeatPassword(!showRepeatPassword);
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setPasswordErrors(validatePassword(newPassword));
    };

    const authLinks = [
        { name: 'Log in', href: '/login' },
        { name: "Register", href: "/register" },
    ];

    const validatePassword = (password) => {
        const errors = [];
        if (password.length < 8) {
            errors.push("Password must be at least 8 characters long.");
        }
        if (!/[a-z]/.test(password)) {
            errors.push("Password must contain at least one lowercase letter.");
        }
        if (!/[A-Z]/.test(password)) {
            errors.push("Password must contain at least one uppercase letter.");
        }
        if (!/\d/.test(password)) {
            errors.push("Password must contain at least one digit.");
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            errors.push(
                "Password must contain at least one special character."
            );
        }
        setIsPasswordValid(errors.length == 0);
        return errors;
    };

    const handleRepeatPasswordChange = (e) => {
        const repeatPassword = e.target.value;
        setRepeatPassword(repeatPassword);

        setRepeatPasswordErrors(validateRepeatPassword(repeatPassword));
    }

    const validateRepeatPassword = (repeatPassword) => {
        const errors = [];
        const error_msg_repeat_password = "Passwords don't match";
        if (!repeatPassword || repeatPassword !== password) {
            errors.push(error_msg_repeat_password);
        }
        setIsRepeatPasswordValid(errors.length == 0);
        return errors;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Get form data
        const formData = new FormData(e.target);
        const formValues = {};
        if (passwordErrors.length > 0 || repeatPasswordErrors.length > 0) {
            return;
        }
        formData.forEach((value, key) => {
            formValues[key] = value;
        });
        formValues["token"] = searchParams.get("token");

        // Call API.register
        const result = await API.resetPassword(formValues);
        if (result.success) {

            const { success, data } = await API.me();
            if(success && data && data['role'] === 'User') {
                window.location.assign("https://app.mydatamazing.com"); // Redirect to chat page
            } else if (success && data && data['role'] === 'Admin') {
                navigate("/dashboard", { state: { source: 'login', status: 'success', user } }); // Redirect to dashboard page
            }
        }
        else {
            if (result?.data)
                setApiError(result.data);
            else {
                if(result?.error)
                    setApiError(result.error);
                else
                    setApiError("Please contact support team at: support@mda.com.au");
            }

        }
    };

    const handleApiErrorClose = () => {
        setApiError("");
    }

    return (
        <>
            <Header authLinks={authLinks} />
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Reset Password
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label
                                htmlFor="password"
                                className={`block text-sm font-medium leading-6 ${
                                    passwordErrors.length
                                        ? "text-red-700"
                                        : "text-gray-900"
                                }`}
                            >
                                New Password
                            </label>
                            <div className="mt-2 relative">
                                <input
                                    id="password"
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    autoComplete="new-password"
                                    required
                                    value={password}
                                    onChange={handlePasswordChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                <button
                                    type="button"
                                    onClick={toggleShowPassword}
                                    className="absolute inset-y-0 right-0 flex items-center px-2"
                                >
                                    {showPassword ? (
                                        <EyeSlashIcon className="h-5 w-5 text-gray-500"/>
                                    ) : (
                                        <EyeIcon className="h-5 w-5 text-gray-500"/>
                                    )}
                                </button>
                            </div>
                            {passwordErrors.length > 0 && (
                                <ul className="mt-2 text-sm text-red-600">
                                    {passwordErrors.map((error, index) => (
                                        <li key={index}>{error}</li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        <div>
                            <label htmlFor="repeat-password"
                                   className="block text-sm font-medium leading-6 text-gray-900">
                                Repeat Password
                            </label>
                            <div className="mt-2 relative">
                                <input
                                    id="repeat-password"
                                    name="repeat-password"
                                    type={showRepeatPassword ? "text" : "password"}
                                    autoComplete="new-password"
                                    required
                                    value={repeatPassword}
                                    onChange={handleRepeatPasswordChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                <button
                                    type="button"
                                    onClick={toggleShowRepeatPassword}
                                    className="absolute inset-y-0 right-0 flex items-center px-2"
                                >
                                    {showRepeatPassword ? (
                                        <EyeSlashIcon className="h-5 w-5 text-gray-500"/>
                                    ) : (
                                        <EyeIcon className="h-5 w-5 text-gray-500"/>
                                    )}
                                </button>
                            </div>
                            {repeatPasswordErrors.length > 0 && (
                                <ul className="mt-2 text-sm text-red-600">
                                    {repeatPasswordErrors.map((error, index) => (
                                        <li key={index}>{error}</li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                disabled={ !isPasswordValid || !isRepeatPasswordValid}
                            >
                                Reset Password
                            </button>
                        </div>
                        {apiError && (
                            <div
                                id="login-failed"
                                className="flex items-center w-full p-2 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                                role="alert"
                            >
                                <div className= "text-red-500 text-center rounded-md">
                                    <p className="m-1 text-sm ">
                                        {apiError}
                                    </p>
                                </div>
                                <button
                                    type="button"
                                    className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                                    data-dismiss-target="#login-failed"
                                    aria-label="Close"
                                    onClick={handleApiErrorClose}
                                >
                                    <XMarkIcon  className="h-5 w-5 text-gray-500" />
                                </button>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </>
    )
}
