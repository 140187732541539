import React, { useEffect, useState } from "react";
import { EyeIcon, EyeSlashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import API from "./core/api";

export default function Account( { user } ) {

    const [isAccountSaveSuccess, setIsAccountSaveSuccess] = useState(false);
    const [accountSaveMsg, setAccountSaveMsg] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Get form data
        const formData = new FormData(e.target);
        const formValues = {};
        formData.forEach((value, key) => {
            formValues[key] = value;
        });

        // formValues['org_id'] = user.org.org_id;
        // formValues['role_id'] = roleId;

        // Call API
        const result = await API.updateUser(formValues);
        if (result.success) {
            setIsAccountSaveSuccess(true);
            // Handle 
            setAccountSaveMsg("Save success");
        } else {
            setIsAccountSaveSuccess(false)
            const errMsg = result && result['error'] && result['error']['detail'] ? result['error']['detail'] : "Save failed"
            setAccountSaveMsg(errMsg);
        }
    };

    const handleAccountFailedClose = (e) => {
        setAccountSaveMsg("");
    };

    return (
        <div className="sm:mx-auto sm:w-full sm:max-w-lg px-3 shrink-0 md:flex-0">
            <div className="relative flex flex-col min-w-0 break-words bg-white">
                <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                    <div className="flex items-center">
                        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                            Edit Profile
                        </h2>
                    </div>
                </div>
                <form className="flex-auto p-6" onSubmit={handleSubmit}>
                    <div className="flex flex-wrap -mx-3">
                        <div className="w-full max-w-full px-3 shrink-0 md:w-12/12 md:flex-0">
                            <div className="mb-4">
                                <label
                                    htmlFor="email"
                                    className="inline-block mb-2 ml-1 font-medium text-xm text-slate-700 dark:text-white/80"
                                >
                                    Email address
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    disabled
                                    defaultValue={(user && user['user']) ? user['user']['email'] : ''}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div className="mb-4">
                                <label
                                    htmlFor="firstName"
                                    className="inline-block mb-2 ml-1 font-medium text-xm text-slate-700 dark:text-white/80"
                                >
                                    First name
                                </label>
                                <input
                                    type="text"
                                    name="firstName"
                                    disabled
                                    defaultValue={(user && user['user']) ? user['user']['first_name'] : ''}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div className="mb-4">
                                <label
                                    htmlFor="lastName"
                                    className="inline-block mb-2 ml-1 font-medium text-xm text-slate-700 dark:text-white/80"
                                >
                                    Last name
                                </label>
                                <input
                                    type="text"
                                    name="lastName"
                                    disabled
                                    defaultValue={(user && user['user']) ? user['user']['last_name'] : ''}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        {/* <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save Profile
                        </button> */}
                    </div>

                    {accountSaveMsg && (
                            <div
                                id="invite-failed"
                                className="flex items-center w-full mt-2 p-2 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                                role="alert"
                            >
                                <div
                                className={`${
                                    isAccountSaveSuccess
                                        ? "text-green-800"
                                        : "text-red-500"
                                } text-center rounded-md`}
                                >
                                    <p className="m-1 text-sm ">
                                        {accountSaveMsg}
                                    </p>
                                </div>
                                <button
                                    type="button"
                                    className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                                    data-dismiss-target="#login-failed"
                                    aria-label="Close"
                                    onClick={handleAccountFailedClose}
                                >
                                    <XMarkIcon className="h-5 w-5 text-gray-500" />
                                </button>
                            </div>
                        )}

                </form>
            </div>
        </div>
    );
}
